<template>
  <!--begin::Wrapper-->
  <div class="w-lg-500px bg-white rounded shadow-sm p-10 p-lg-15 mx-auto">
    <!--begin::Form-->
    <Form
      class="form w-100 fv-plugins-bootstrap5 fv-plugins-framework"
      id="kt_login_password_reset_form"
    >
      <!--begin::Heading-->
      <div class="text-center mb-10">
        <!--begin::Title-->
        <h1 class="text-dark mb-3">{{ $t("forget_password.title") }}</h1>
        <!--end::Title-->

        <!--begin::Link-->
        <div class="text-gray-400 fw-bold fs-4">
          {{ $t("forget_password.subtitle") }}
        </div>
        <!--end::Link-->
      </div>
      <!--begin::Heading-->
      <div
        :class="`alert alert-${message.color}`"
        role="alert"
        v-if="message.show && message.text"
      >
        {{ message.text }}
      </div>
      <!--begin::Input group-->
      <div class="fv-row mb-10 form-group">
        <label class="form-label fw-bolder text-gray-900 fs-6">{{
          $t("forget_password.fields.email.label")
        }}</label>
        <input
          class="form-control form-control-solid"
          type="email"
          :placeholder="$t('forget_password.fields.email.placeholder')"
          name="email"
          ref="femail"
          autocomplete="off"
        />
      </div>
      <!--end::Input group-->

      <!--begin::Actions-->
      <div class="d-flex flex-wrap justify-content-center pb-lg-0">
        <v-btn
          :loading="loading.repass"
          @click="repass"
          color="primary"
          large
          elevation="0"
          ref="kt_login_forget_submit"
          class="btn btn-lg btn-primary font-weight-bold mr-5"
          style="background-color: #3699FF;width:150px;"
        >
          {{ $t("common.submit") }}
        </v-btn>
        <router-link
          to="/login"
          class="btn btn-lg btn-light-primary font-weight-bold"
          >{{ $t("common.cancel") }}</router-link
        >
      </div>
      <!--end::Actions-->
    </Form>
    <!--end::Form-->
  </div>
  <!--end::Wrapper-->
</template>

<script>
import formValidation from "@/assets/plugins/formvalidation/dist/es6/core/Core";

// FormValidation plugins
import Trigger from "@/assets/plugins/formvalidation/dist/es6/plugins/Trigger";
import Bootstrap from "@/assets/plugins/formvalidation/dist/es6/plugins/Bootstrap";
import SubmitButton from "@/assets/plugins/formvalidation/dist/es6/plugins/SubmitButton";

import KTUtil from "@/assets/js/components/util";
import { mapGetters, mapState } from "vuex";
import { FORGET_PASSWORD } from "@/core/services/store/auth.module";
import Swal from "sweetalert2";

export default {
  name: "repass",
  data() {
    return {
      fv: null,
      fv1: null,
      fv2: null,
      state: "repass",
      // Remove this dummy login info
      form: {
        email: "",
        password: ""
      }
    };
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors,
      loading: state => state.auth.loading,
      message: state => state.message.message
    }),
    ...mapGetters(["currentUser"]),

    backgroundImage() {
      return (
        process.env.VUE_APP_BASE_URL +
        process.env.BASE_URL +
        "media/svg/illustrations/login-visual-6.svg"
      );
    }
  },
  mounted() {
    const forgot_form = KTUtil.getById("kt_login_password_reset_form");
    this.fv = formValidation(forgot_form, {
      fields: {
        email: {
          validators: {
            notEmpty: {
              message: this.$t("forget_password.fields.email.validation.empty")
            },
            emailAddress: {
              message: this.$t("forget_password.fields.email.validation.regex")
            }
          }
        }
      },
      plugins: {
        trigger: new Trigger(),
        submitButton: new SubmitButton(),
        bootstrap: new Bootstrap()
      }
    });

    this.fv.on("core.form.invalid", () => {
      Swal.fire({
        title: "",
        text: this.$t("common.dialog_validate.content"),
        icon: "error",
        confirmButtonClass: "btn btn-secondary",
        heightAuto: false
      });
    });
  },
  methods: {
    repass() {
      this.fv.validate().then(isValid => {
        if (isValid === "Valid") {
          var email = this.$refs.femail.value;
          // set spinner to submit button
          this.loading.repass = true;

          // send login request
          this.$store
            .dispatch(FORGET_PASSWORD, {
              email: email
            })
            .then(() => {
              this.loading.repass = false;
            })
            .catch(() => {
              this.loading.repass = false;
            });
        } else {
          Swal.fire({
            title: "",
            text: this.$t("common.dialog_validate.content"),
            icon: "error",
            confirmButtonClass: "btn btn-secondary",
            heightAuto: false
          });
        }
      });
    }
  }
};
</script>
